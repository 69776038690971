import * as React from 'react';
import { Link } from 'gatsby';
import CategoryLayout from '../components/CategoryLayout';
import { useTags } from '../operations/queries';
import { Tag } from '../models/Tag';

export default function Tags(): JSX.Element {
  const tags = useTags();

  // console.log(tags);
  return (
    <CategoryLayout title="Tags">
      {tags && (
        <ul>
          {tags.map(function (tag: Tag) {
            return (
              <Link key={tag.slug} to={`${tag.slug}`}>
                <li style={{ color: '#FF6600' }}>{tag.nombre}</li>
              </Link>
            );
          })}
        </ul>
      )}
    </CategoryLayout>
  );
}
